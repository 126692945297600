
<template>
  <div id="projects">
    <section class="max-w-6xl mx-auto p-8">
      <h2 class="text-3xl mb-4">things that i made</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <a referrerpolicy="no-referrer" href="https://movies.samj.app/">
          <div class="bg-gray-700 p-4 rounded-xl shadow-lg hover:bg-gray-800 transition duration-300">
            <h3 class="text-xl font-semibold mb-2">SamJ Movies</h3>
            <p class="text-sm">watch any movie, for free</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://sports.samj.app/">
          <div class="bg-gray-700 p-4 rounded-xl shadow-lg hover:bg-gray-800 transition duration-300">
            <h3 class="text-xl font-semibold mb-2">SamJ Sports</h3>
            <p class="text-sm">free app to watch sports, has some ads</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://games.samj.app/">
          <div class="bg-gray-700 p-4 rounded-xl shadow-lg hover:bg-gray-800 transition duration-300">
            <h3 class="text-xl font-semibold mb-2">SamJ Games</h3>
            <p class="text-sm">games site that will never be blocked</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://music.samj.app/">
          <div class="bg-gray-700 p-4 rounded-xl shadow-lg hover:bg-gray-800 transition duration-300">
            <h3 class="text-xl font-semibold mb-2">SamJ Music</h3>
            <p class="text-sm">listen to any music, unblocked</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://books.samj.app/">
          <div class="bg-gray-700 p-4 rounded-xl shadow-lg hover:bg-gray-800 transition duration-300">
            <h3 class="text-xl font-semibold mb-2">SamJ Books</h3>
            <p class="text-sm">paying for books is lame</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://os.samj.app/">
          <div class="bg-gray-700 p-4 rounded-xl shadow-lg hover:bg-gray-800 transition duration-300">
            <h3 class="text-xl font-semibold mb-2">SamJ OS</h3>
            <p class="text-sm">a WIP hack for ChromeOS</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://videos.samj.app">
          <div class="bg-gray-700 p-4 rounded-xl shadow-lg hover:bg-gray-800 transition duration-300">
            <h3 class="text-xl font-semibold mb-2">SamJ Videos</h3>
            <p class="text-sm">unblocked youtube, doesn't work very well</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://samj.app">
          <div class="bg-gray-700 p-4 rounded-xl shadow-lg hover:bg-gray-800 transition duration-300">
            <h3 class="text-xl font-semibold mb-2">…</h3>
            <p class="text-sm">coming soon 🤫</p>
          </div>
        </a>
        <a referrerpolicy="no-referrer" href="https://everything.samj.app">
          <div class="bg-gray-700 p-4 rounded-xl shadow-lg hover:bg-gray-800 transition duration-300">
            <h3 class="text-xl font-semibold mb-2">everything else</h3>
            <p class="text-sm">everything else i've ever made</p>
          </div>
        </a>
      </div>
    </section>
  </div>
<div id="connect">
    <section class="max-w-6xl mx-auto p-8">
        <h2 class="text-3xl mb-4">stalk me</h2>
        <div class="grid sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <a referrerpolicy="no-referrer" href="https://message.samj.app">
                <button class="bg-black text-white text-lg shadow-lg py-2 px-4 rounded-xl hover:bg-gray-800 transition duration-300 w-full">
                    <i class="fa-solid fa-envelope fa-lg"></i> Send me a message
                </button>
              </a>
            </div>
             <div>
              <a referrerpolicy="no-referrer" href="https://chat.samj.app">
                <button class="bg-black text-white text-lg shadow-lg py-2 px-4 rounded-xl hover:bg-gray-800 transition duration-300 w-full">
                    <i class="fa-solid fa-comment fa-lg"></i> SamJ Chat
                </button>
              </a>
            </div>
            <div>
                <a referrerpolicy="no-referrer" href="https://github.com/SamJ2104" target="_blank">
                    <button class="bg-black text-white text-lg shadow-lg py-2 px-4 rounded-xl hover:bg-gray-800 transition duration-300 w-full">
                        <i class="fab fa-github fa-lg"></i> GitHub
                    </button>
                </a>
            </div>
            <div>
                <a referrerpolicy="no-referrer" href="https://tiktok.com/@_samjam_" target="_blank">
                    <button class="bg-black text-white text-lg shadow-lg py-2 px-4 rounded-xl hover:bg-gray-800 transition duration-300 w-full">
                        <i class="fa-brands fa-tiktok fa-lg"></i> TikTok
                    </button>
                </a>
            </div>
        </div>
    </section>
</div>
</template>
